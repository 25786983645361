import { ZodNumber, ZodOptional, ZodString } from 'zod'
import * as zod from 'zod'

export function useRules() {
  const { t } = useI18n()

  const requiredMessage = t('base.form.required')
  function requiredString(min = 1, minMessage = requiredMessage): ZodString {
    return zod.string({ required_error: requiredMessage }).min(min, minMessage)
  }

  function optionalString(): ZodOptional<ZodString> {
    return zod.string().optional()
  }

  return { requiredString, optionalString }
}
