<script lang="ts" setup>
import type { AutocompleteItem } from '~/types/form'

const { t } = useI18n()
const slots = useSlots()

const emit = defineEmits(['update:modelValue', 'blur'])

type Props = {
  modelValue?: string | boolean
  parentName?: string
  propertyName?: string
  type?: string
  light?: boolean
  label?: string
  translatedLabel?: string
  icon?: string
  required?: boolean
  items?: AutocompleteItem[]
  itemsLoading?: boolean
  error?: string
  autocomplete?: string
  hideDetails?: boolean
  placeholder?: string
  alignStart?: boolean
  disabled?: boolean
}

// TODO: Typescript errors
const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  parentName: undefined,
  propertyName: null,
  type: 'text',
  light: false,
  label: null,
  translatedLabel: null,
  icon: undefined,
  required: false,
  items: [],
  itemsLoading: false,
  error: null,
  autocomplete: null,
  hideDetails: false,
  placeholder: '',
  alignStart: false,
  disabled: false,
})

const id: ComputedRef<string> = computed((): string => {
  return (props.parentName ?? '') + '-' + props.propertyName
})

const getLabel: ComputedRef<string> = computed((): string => {
  const translated = props.translatedLabel ?? t(props.label)
  return props.required ? translated + ' *' : translated
})

const hasError: ComputedRef<boolean> = computed((): boolean => {
  return props.error !== null && props.error !== undefined
})

const isCheckbox: ComputedRef<boolean> = computed((): boolean => {
  return props.type === 'checkbox'
})

const isSelect: ComputedRef<boolean> = computed((): boolean => {
  return props.type === 'select'
})

const isTextarea: ComputedRef<boolean> = computed((): boolean => {
  return props.type === 'textarea'
})

defineExpose({
  $_veeValidate: {
    name() {
      return props.propertyName
    },
    value() {
      return props.modelValue
    },
  },
})
</script>

<template>
  <div
    v-if="isCheckbox"
    class="-mt-4 ml-8 flex duration-700"
    :class="{ 'mb-6': !hideDetails }"
  >
    <FormCheckbox
      :model-value="modelValue"
      :property-name="propertyName"
      :parent-name="parentName"
      :light="light"
      :label-left="label"
      :translated-label-left="translatedLabel"
      :required="required"
      thick-border
      @update:model-value="(val: boolean) => emit('update:modelValue', val)"
      @blur="emit('blur')"
    >
      <template v-if="slots.label" #label-left>
        <slot name="label" :label-for="id" />
      </template>
    </FormCheckbox>
  </div>
  <div
    v-else-if="isSelect"
    class="relative ml-2.5 flex duration-700"
    :class="{
      'text-neutral-100': light,
      'pr-1.5': icon,
      'mb-6': !hideDetails,
      'opacity-40': disabled,
    }"
  >
    <Icon v-if="icon" class="mr-2.5 text-xl" :name="'fa6-solid:' + icon" />
    <div class="flex w-full flex-col">
      <select
        :id="id"
        :value="modelValue"
        :name="propertyName"
        class="peer w-full appearance-none border-2 border-x-0 border-t-0 border-black bg-transparent pb-0.5 text-center text-xl focus:shadow-transparent focus:outline-0"
        :class="{
          'border-neutral-100 bg-black text-neutral-100': light,
          'border-red-500': hasError,
          'border-b-0': itemsLoading,
        }"
        :disabled="disabled"
        @input="emit('update:modelValue', $event.target.value)"
        @blur="emit('blur')"
      >
        <option selected disabled />
        <option
          v-for="item in items"
          :key="item.value"
          class="text-black"
          :value="item.value"
          :selected="modelValue == item.value"
        >
          {{ item.text }}
        </option>
      </select>
      <div v-if="itemsLoading" class="w-full">
        <div
          class="h-[2px] w-full overflow-hidden bg-neutral-200 dark:bg-neutral-700"
        >
          <div
            class="h-full w-full origin-left-right animate-progress bg-blue-500 dark:bg-blue-400"
          ></div>
        </div>
      </div>
      <label
        :for="id"
        class="pointer-events-none absolute top-[10%] w-full -translate-y-1/2 scale-100 text-left text-xl opacity-40 duration-150 peer-focus:pointer-events-auto peer-focus:translate-y-[-125%] peer-focus:text-xs peer-focus:opacity-30"
        :class="{
          'pointer-events-auto translate-y-[-125%] text-xs opacity-30':
            modelValue !== '' && modelValue !== null,
        }"
      >
        {{ getLabel }}
      </label>
      <div v-if="!hideDetails" class="pt-1.5">
        <div class="min-h-[18px] text-left text-xs text-red-500">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="relative ml-2.5 flex duration-700"
    :class="{
      'text-neutral-100': light,
      'pr-1.5': icon,
      'mb-6': !hideDetails,
      'opacity-40': disabled,
    }"
  >
    <Icon v-if="icon" class="mr-2.5 text-xl" :name="'fa6-solid:' + icon" />
    <div class="flex w-full flex-col">
      <template v-if="isTextarea">
        <textarea
          :id="id"
          :value="modelValue"
          :rows="3"
          :type="type"
          :placeholder="placeholder ? $t(placeholder) : ''"
          :name="propertyName"
          class="peer border-x-0 border-b-2 border-t-0 border-black bg-transparent pb-0.5 text-center text-xl focus:shadow-transparent focus:outline-0 pt-4"
          :class="{
            'border-neutral-100 bg-black text-neutral-100': light,
            'border-red-500': hasError,
            '!text-start': alignStart,
          }"
          :autocomplete="autocomplete"
          @input="emit('update:modelValue', $event.target.value)"
          @blur="emit('blur')"
        />
      </template>
      <template v-else>
        <input
          :id="id"
          :value="modelValue"
          :type="type"
          :placeholder="placeholder ? $t(placeholder) : ''"
          :name="propertyName"
          class="peer border-x-0 border-b-2 border-t-0 border-black bg-transparent pb-0.5 text-center text-xl focus:shadow-transparent focus:outline-0"
          :class="{
            'border-neutral-100 bg-black text-neutral-100': light,
            'border-red-500': hasError,
            'w-full': type === 'password',
            '!text-start': alignStart,
          }"
          :autocomplete="autocomplete"
          :disabled="disabled"
          @input="emit('update:modelValue', $event.target.value)"
          @blur="emit('blur')"
        />
      </template>
      <label
        :for="id"
        class="pointer-events-none absolute top-[10%] w-full -translate-y-1/2 scale-100 text-left text-xl opacity-40 duration-150 peer-focus:pointer-events-auto peer-focus:translate-y-[-125%] peer-focus:text-xs peer-focus:opacity-30"
        :class="{
          'pointer-events-auto translate-y-[-125%] text-xs opacity-30':
            (modelValue !== '' && modelValue !== null) || placeholder,
        }"
      >
        {{ getLabel }}
      </label>
      <div v-if="!hideDetails" class="pt-1.5">
        <div class="min-h-[18px] text-left text-xs text-red-500">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>
